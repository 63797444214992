import * as React from "react";
import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  asChild?: boolean;
  className?: string;
}

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(({ className, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button";
  return <Comp className={cn("font-semibold text-3xl lg:text-5xl", className)} ref={ref} {...props} />;
});
Heading.displayName = "Heading";

export { Heading };
